import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Menu.css";

const menuItems = [
  { id: 1, name: "Home", path: "/" },
  { id: 2, name: "O nas", path: "/about" },
  { id: 3, name: "Usługi", path: "/services" },
  { id: 4, name: "Kontakt", path: "/contact" },
];

export default function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Sprawdzanie rozmiaru okna, by przełączać mobilne menu
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) setIsOpen(false); // Zamknięcie menu po powiększeniu ekranu
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className="menu-container">
      {/* LOGO */}
      <h5 className="logo">AGUR sp. z o.o.</h5>

      {/* HAMBURGER - tylko na mobile */}
      {isMobile && (
        <button className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? "✖" : "☰"}
        </button>
      )}

      {/* MENU */}
      <nav
        className={`menu ${
          isMobile ? (isOpen ? "open" : "closed") : "desktop"
        }`}
      >
        <ul>
          {menuItems.map((item) => (
            <li key={item.id}>
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => isMobile && setIsOpen(false)}
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}
