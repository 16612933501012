import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from "./components/Menu";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";

import Contact from "./pages/Contact";

export default function App() {
  const theme = "dark-theme";

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <Router basename="/">
      {/* Menu powinno być niezależne od treści stron */}
      <Menu />
      <main className="page-content"></main>
      {/* Kontener na całą stronę pod menu */}
      <main className="page-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />

          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
    </Router>
  );
}
