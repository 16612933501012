import building1 from "../images/1.jpg";
import building5 from "../images/5.jpg";
import miko2 from "../images/miko2.jpg";
import { Table, Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Home() {
  return (
    <div class="container-lg">
      <Container>
        <Row>
          <div>
            <img
              src={miko2}
              alt="Description"
              className="rounded-image"
              //style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </Row>
        <Row>
          <div style={{ width: "800px", height: "600px", overflow: "hidden" }}>
            <h6>Wykonywanie opinii i ekspertyz budowlanych</h6>
            <h6>Kontrole okresowe budynków</h6>
            <h6>
              Sporządzanie opinii technicznych w związku z zakupem mieszkań lub
              budynków
            </h6>
            <h6>Ocena stanu technicznego budynków</h6>
            <h6>Analiza poprawności realizacji budowy</h6>
          </div>
        </Row>
      </Container>
    </div>
  );
}
