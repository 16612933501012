import building1 from "../images/1.jpg";
import building5 from "../images/5.jpg";
import miko3 from "../images/miko3.jpg";
import { Table, Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function About() {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h5>
              Ekspert z zakresu budownictwa<hr></hr>mgr inż. Andrzej Nurek
            </h5>
          </Col>
          <Col>
            <div>
              <img
                src={miko3}
                alt="Description"
                className="rounded-image"
                //style={{ width: "100%", height: "200%", objectFit: "cover" }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <h6>Absolwent Wydziału Budownictwa Politechniki Śląskiej</h6>
          <h6>Ukończone studia podyplomowe w zakresie wyceny nieruchomości</h6>
          <h6>
            Uprawnienia budowlane w zakresie wykonawstwa w specjalności
            konstrukcyjno-budowlanej bez ograniczeń
          </h6>
          <h6>Członek Polskiego Związku Inżynierów i Techników Budownictwa</h6>
          <h6>Członek Śląskiej Okręgowej Izby Inżynierów budownictwa</h6>
          <h6>Ponad 30 lat doświadczenia</h6>
          <h6>Nadzór inwestorski</h6>
          <h6>Kierownictwo budowy i robót</h6>
          <h6>Koordynowanie inwestycji</h6>
          <h6>Autor i współautor opinii i ekspertyz budowlanych</h6>
          <h6>Doświadczenie w pracy przy obiektach zabytkowych</h6>
          <h6>
            Zakres aktywności: budownictwo kubaturowe mieszkalne i
            biurowo-usługowe, budownictwo przemysłowe, hale magazynowe i
            produkcyjne, budownictwo telekomunikacyjne, prawo budowlane.
            Zabezpieczenia ogniochronne konstrukcji.
          </h6>
          <h6>
            Konsultant przy opracowaniu systemów informatycznych obsługujących
            procesy budowlane
          </h6>
          <h6>Ekspert ds. budownictwa przy likwidacji szkód budowlanych.</h6>
        </Row>
      </Container>
    </div>
  );
}
