import building1 from "../images/1.jpg";
import building5 from "../images/5.jpg";
import miko6 from "../images/miko6.jpg";
import { Table, Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Contact() {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div>
              <img
                src={miko6}
                alt="Description"
                className="rounded-image"
                //style={{ width: "100%", height: "200%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col>
            <div>
              <h1>tel. 512 353 863</h1>
              <h1>e-mail: biuro@agur.pl</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
